<template>
  <b-row>
    <b-col cols="auto" class="ml-auto pr-0">
      <b-badge :variant="variantSuccess">{{ template.count }}</b-badge>
    </b-col>
    <b-col cols="3" class="text-right pl-0">
      <b-badge :id="'tt-warnung-' + template.id" :variant="variantDanger" class="ml-3">
        {{ template.count_disallowed }}
      </b-badge>
      <b-badge :id="'tt-allowed-' + template.id" :variant="variantSuccess" class="ml-1">
        {{ template.count_allowed }}
      </b-badge>
      <b-tooltip :target="'tt-warnung-' + template.id" triggers="hover">Warnung</b-tooltip>
      <b-tooltip :target="'tt-allowed-' + template.id" triggers="hover">Erlaubt</b-tooltip>
    </b-col>
  </b-row>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "BadgeCounterTemplate",
  props: ['template'],
  computed: {
    ...mapGetters(['userThemeId']),
    variantSuccess() {
      if(this.userThemeId === 2) { return 'success-deuteranopia' }
      return 'success'
    },
    variantDanger() {
      if(this.userThemeId === 2) { return 'danger-deuteranopia' }
      return 'danger'
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
